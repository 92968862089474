const Permisos =[ 
   { Rol: 'Superuser', 
     Permisos: [
        'edit.account',
        'dashboard.view',
        'dispatch.view',
        'logs.view',
        'users.view',
        'users.view.role',
        'users.manage',
        "roles.view",
        "roles.giveAdminRole",
        'reports.download',
        'dispatch.view',
        'dispatch.index',
        'dispatch.create',
        'dispatch.assign',
        'documents.view',
        'documents.index',
        'documents.manage',
        'payments.view',
        'payments.index',
        'payments.manage',
        'pedidor.view',
        'tramitador_servicios.view',
        'administrativa.view',
        'jefe_operaciones.view',
        'tramitador_aduanero.view',
        'providers.manage',
        'providers.view'
    ]
    },
    {Rol : 'Admin', 
     Permisos: [
        'edit.account',
        'dashboard.view',
        'users.view',
        'users.view.role',
        'users.manage',
        "roles.view",
        "roles.giveAdminRole",
        'reports.download',
        'dispatch.view',
        'dispatch.index',
        'dispatch.create',
        'dispatch.assign',
        'documents.view',
        'documents.index',
        'documents.manage',
        'payments.view',
        'payments.index',
        'payments.manage',
        'providers.manage',
        'providers.view'
     ]},
    {Rol : 'Gestor',
    Permisos:[
        'users.view.role',
        'edit.account',
        'dashboard.view',
        'reports.download',
        'dispatch.view',
        'dispatch.index',
        'dispatch.create',
        'documents.view',
        'documents.index',
    ]},
    {Rol: 'Pedidor',
    Permisos: [
        'users.view.role',
        'edit.account',
        'dashboard.view',
        'reports.download',
        'dispatch.view',
        'dispatch.index',
        'dispatch.assign',
        'documents.view',
        'documents.index',
        'documents.manage',
        'payments.view',
        'payments.index',
        'payments.manage',
        'pedidor.view',
        'dispatch.assign',
        'dispatch.create'
    ]},
    {Rol: 'Pedidor Exportaciones',
    Permisos: [
        'users.view.role',
        'edit.account',
        'dashboard.view',
        'reports.download',
        'dispatch.view',
        'dispatch.index',
        'dispatch.assign',
        'documents.view',
        'documents.index',
        'documents.manage',
        'payments.view',
        'payments.index',
        'payments.manage',
        'pedidor.view',
        'dispatch.assign'
    ]},
    {Rol: 'Tramitador_servicios',
    Permisos: [
        'edit.account',
        'dispatch.view',
        'dispatch.index',
        'documents.view',
        'documents.index',
        'documents.manage',
        'payments.view',
        'payments.index',
        'payments.manage',
        'tramitador_servicios.view'
    ]},
    {Rol: 'Administrativa',
    Permisos: [
        'edit.account',
        'dispatch.view',
        'dispatch.index',
        'documents.view',
        'documents.index',
        'documents.manage',
        'payments.view',
        'payments.index',
        'payments.manage',
        'administrativa.view'
    ]},
    {Rol: 'Operaciones',
    Permisos: [
        'edit.account',
        'dispatch.view',
        'dispatch.index',
        'documents.view',
        'documents.index',
        'documents.manage',
        'payments.view',
        'payments.index',
        'payments.manage',
        'tramitador_aduanero.view'
    ]},
    {Rol: 'Supervisor',
    Permisos: [
        'edit.account',
        'dashboard.view',
        'dispatch.view',
        'logs.view',
        // 'users.view',
        // 'users.view.role',
        // 'users.manage',
        "roles.view",
        "roles.giveAdminRole",
        'reports.download',
        'dispatch.view',
        'dispatch.index',
        'dispatch.create',
        'dispatch.assign',
        'documents.view',
        'documents.index',
        'documents.manage',
        'payments.view',
        'payments.index',
        'payments.manage',
        'pedidor.view',
        'tramitador_servicios.view',
        'administrativa.view',
        'jefe_operaciones.view',
        'tramitador_aduanero.view',
        'providers.manage',
        'providers.view'
    ]}

]

export default Permisos;