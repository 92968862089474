var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"12"}},[(_vm.agregarDocumento && !_vm.editar)?_c('form-documento',{attrs:{"postDocumento":_vm.postDocumento,"documentos":_vm.documentos,"cancelar":_vm.cancelar}}):_vm._e(),(_vm.agregarDocumento && _vm.editar && _vm.documentoEditar)?_c('form-documento-editar',{attrs:{"postDocumento":_vm.updateDocumento,"documentos":_vm.documentos,"documentoEditar":_vm.documentoEditar,"cancelar":_vm.cancelar,"files_old":_vm.files_old}},[_c('template',{slot:"cancelar"},[_c('b-button',{staticClass:"btn-secundario btn-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v(" Cancelar ")])],1)],2):_vm._e()],1),_c('b-col',{staticClass:"text-left mx-auto",attrs:{"cols":"12"}},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.documentosTabla,"fields":_vm.fields,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center"},[_vm._v("No hay documentos disponibles")])]},proxy:true},{key:"cell(tipo)",fn:function(row){return [(row.item.tipo_documentos_id != 20)?_c('p',[_vm._v(" "+_vm._s(row.item.tipo.nombre)+" ")]):_c('p',[_vm._v(_vm._s(row.item.base.nombre_documento))])]}},{key:"cell(documentos)",fn:function(row){return _vm._l((row.item.archivos),function(documento,index){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:index,staticClass:"btn-archivo-tabla",staticStyle:{"width":"20px","height":"80px"},attrs:{"title":documento.ruta},on:{"click":function($event){return _vm.downloadDocumento(row.item.id)}}},[(documento.ruta.split('.').at(-1) == 'pdf')?_c('inline-svg',{attrs:{"src":"../../assets/images/archivo-pdf.svg"}}):_vm._e(),(
                documento.ruta.split('.').at(-1) == 'png' ||
                documento.ruta.split('.').at(-1) == 'jpg' ||
                documento.ruta.split('.').at(-1) == 'jpeg'
              )?_c('inline-svg',{attrs:{"src":"../../assets/images/archivo-png.svg"}}):_vm._e(),(
                documento.ruta.split('.').at(-1) == 'xlsx' ||
                documento.ruta.split('.').at(-1) == 'xls'
              )?_c('inline-svg',{attrs:{"src":"../../assets/images/732220.svg"}}):_vm._e()],1)})}},{key:"cell(fecha_recepcion)",fn:function(row){return [_c('p',[_vm._v(_vm._s(_vm.moment(row.item.fecha_recepcion).format("DD-MM-YYYY")))])]}},{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"col text-center"},[_c('b-button',{staticClass:"btn-extra-primario btn-small mr-1",on:{"click":function($event){return _vm.downloadDocumento(row.item.id)}}},[_vm._v(" Ver ")]),(_vm.agregarDocumento)?_c('b-button',{staticClass:"btn-extra-secundario btn-small mr-1",on:{"click":function($event){return _vm.editarDocumento(row.item)}}},[_vm._v(" Editar "),_c('inline-svg',{staticClass:"icono-titulo",attrs:{"src":"../../assets/images/Editar.svg","title":"My Image"}})],1):_vm._e(),(_vm.agregarDocumento)?_c('b-button',{staticClass:"btn-extra-primario btn-small",on:{"click":function($event){return _vm.eliminarDocumento(row.item.id, row.item.tipo.nombre)}}},[_vm._v(" Eliminar "),_c('inline-svg',{staticClass:"icono-titulo",attrs:{"src":"../../assets/images/basura.svg","title":"My Image"}})],1):_vm._e()],1),(_vm.validate_permission)?_c('div',{staticClass:"col text-center"},[_c('b-button',{staticClass:"btn-extra-secundario btn-small mr-1",on:{"click":function($event){return _vm.validar(row.item.id, 'Aceptado')}}},[_vm._v(" Aceptado "),_c('inline-svg',{staticClass:"icono-titulo",attrs:{"src":"../../assets/images/Editar.svg","title":"My Image"}})],1),_c('b-button',{staticClass:"btn-extra-primario btn-small",on:{"click":function($event){return _vm.validar(row.item.id, 'Rechazado')}}},[_vm._v(" Rechazado "),_c('inline-svg',{staticClass:"icono-titulo",attrs:{"src":"../../assets/images/basura.svg","title":"My Image"}})],1)],1):_vm._e()]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }